/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-05-16 16:46:03
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-05-16 16:46:28
 */
import request from "@/utils/request";
import { isNavHorizontal } from "@/utils/index";
import { resetRouter } from "@/router";

export function getLoadMenu(data) {
  return request({
    url: "/ym-auth-server/u/auth-server/v2/access/loadMenu",
    method: "post",
    data,
  }).then((res) => {
    if (res.retcode === 0) {
      localStorage.setItem("menuList", JSON.stringify(res.data));
      let type = isNavHorizontal() ? "horizontal" : "Layout";
      sessionStorage.setItem("layoutType", type);
      resetRouter();
    }
    return res;
  });
}

export function checkPermission(data) {
  return request({
    url: "/access/checkPermission",
    method: "post",
    data,
  });
}

//获取字典项
export function getDictByCode(data) {
  return request({
    url: "/pass-mgmt/v2/dict/query-item-list",
    method: "post",
    data,
  });
}
