/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2022-08-01 17:50:15
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-07-26 10:36:49
 */
const getters = {
  sidebar: (state) => state.app.sidebar,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,

  token: (state) => {
    if (!window.__POWERED_BY_QIANKUN__) {
      return state.user.token;
    }
    return state.qiankun.qiankunState.token;
  },
  projectId: (state) => {
    if (!window.__POWERED_BY_QIANKUN__) {
      return state.user.projectId;
    }
    return state.qiankun.qiankunState.projectId;
  },
  currentSpaceId: (state) => {
    if (!window.__POWERED_BY_QIANKUN__) {
      return state.user.currentSpaceId;
    }
    return state.qiankun.qiankunState.spaceId;
  },
  userInfo: (state) => {
    if (!window.__POWERED_BY_QIANKUN__) {
      return state.user.userInfo;
    }
    return state.qiankun.qiankunState.user;
  },
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  introduction: (state) => state.user.introduction,
  roles: (state) => state.user.roles,
  permission_routes: (state) => state.permission.routes,
  errorLogs: (state) => state.errorLog.logs,
  getNameOrPhone: (state) => {
    let info = {};
    if (!window.__POWERED_BY_QIANKUN__) {
      info = state.user.userInfo;
    } else {
      info = state.qiankun.qiankunState.user;
    }
    let name = info.relaname || info.mobile || "";
    return name ? "【" + name + "】" : "";
  },
};
export default getters;
