<template>
  <div :class="classObj" class="app-wrapper">
    <div
      v-if="device === 'mobile' && sidebar.opened"
      class="drawer-bg"
      @click="handleClickOutside"
    />

    <div :class="{ hasTagsView: needTagsView }" class="main-container">
      <div :class="{ 'fixed-header': fixedHeader }">
        <Navbar2 ref="navBarRef" :navList="navList" />
      </div>

      <app-main v-if="readyShowApp" />
    </div>

    <el-dialog
      title="提示"
      :visible.sync="no_dialog"
      width="30%"
      :show-close="false"
    >
      <div>您没有访问该系统的权限</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="go2Login">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import RightPanel from "@/components/RightPanel";
import { AppMain, Navbar2 } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from "vuex";
import { getLoadMenu } from "@/api/loadMenu";
import {
  setLocalStorage,
  getLocalStorage,
  ssoCacheKey,
  getCurrentSpaceId,
  getCookies,
  ssoCacheClear,
  loginCacheClear
} from "@/utils/auth";
import { ssoInfo } from "../api/user";
import { terrace } from "../utils/constants";
export default {
  inject: ["reload"],
  name: "Layout",
  components: {
    AppMain,
    Navbar2,
    RightPanel
  },
  mixins: [ResizeMixin],
  data() {
    return {
      readyShowApp: false,
      dialogVisible: false,
      value: "",
      projectSpaceTree: "",
      selCurrentSpaceId: "",
      showMenu: false,
      no_dialog: false,
      navList: {}
    };
  },
  computed: {
    ...mapState({
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
      needTagsView: state => state.settings.tagsView,
      fixedHeader: state => state.settings.fixedHeader,
      currentSpaceId: state => state.user.currentSpaceId + "",
      userTree: state => state.user.userTree,
      token: state => state.user.token
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    }
  },
  mounted() {
    if (!this.token) {
      loginCacheClear();
      this.$router.push("/login");
    }
    try {
      const projectSpaceTree = JSON.parse(
        localStorage.getItem("projectSpaceTree")
      );
      this.projectSpaceTree = projectSpaceTree;
      this.initSsoSpaceInfo();
    } catch (e) {}
  },
  methods: {
    go2Login() {
      this.no_dialog = false;
      loginCacheClear();
      this.$router.push("/login");
    },
    noExtent(e) {
      this.no_dialog = e;
    },
    handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", { withoutAnimation: false });
    },
    //
    initSsoSpaceInfo() {
      const envInfo = envInfos();
      const ssoUserInfo = getCookies(envInfo.userInfo);
      const currentSpaceId = getCurrentSpaceId();
      if (!ssoUserInfo || !currentSpaceId) {
        return;
      }
      try {
        const ssoMember = JSON.parse(ssoUserInfo);
        setLocalStorage(ssoCacheKey.userInfo, JSON.stringify(ssoMember));
        this.$refs.navBarRef.realName = ssoMember.relaname;
        const currentSpaceId = getCurrentSpaceId();
        const projectList = this.projectSpaceTree;
        const spaceInfo = this.getSelCurrentSpaceInfo(
          projectList,
          currentSpaceId
        );
        this.setCurrentSpaceInfo(projectList, spaceInfo);
      } catch (e) {}
    },

    /**
     * 设置当前空间信息
     */
    setCurrentSpaceInfo(projectList, spaceInfo) {
      setLocalStorage(
        ssoCacheKey.projectSpaceTree,
        JSON.stringify(projectList)
      );
      this.$refs.navBarRef.treeData = projectList;
      if (spaceInfo && spaceInfo.selSpaceId) {
        this.$store.dispatch("user/changeCurrentSpaceId", spaceInfo.selSpaceId);
        setLocalStorage(ssoCacheKey.currentSpaceId, spaceInfo.selSpaceId);
        setLocalStorage(ssoCacheKey.rootSpaceId, spaceInfo.rootSpaceId);
        setLocalStorage(
          ssoCacheKey.faceInfo,
          JSON.stringify(spaceInfo.selProjectInfo)
        );
        this.$refs.navBarRef.currentSpaceId = spaceInfo.selSpaceId;
        this.$refs.navBarRef.spaceName = spaceInfo.selSpaceName;
        this.getloadMenuList();
        return;
      }
    },
    /**
     * 获取当前空间信息
     */
    getSelCurrentSpaceInfo(projectList, currentSpaceId) {
      if (currentSpaceId) {
        for (let i = 0; i < projectList.length; i++) {
          const projectInfo = projectList[i];
          if (currentSpaceId == projectInfo.spaceId) {
            const spaceInfo = {};
            spaceInfo.selProjectInfo = projectInfo;
            spaceInfo.selSpaceName = projectInfo.spaceName;
            spaceInfo.selSpaceId = currentSpaceId;
            spaceInfo.rootSpaceId = projectInfo.rootId;
            setLocalStorage("projectId", projectInfo.projectId);
            setLocalStorage(
              "dredgeLiftControlServer",
              projectInfo.dredgeLiftControlServer
            );
            return spaceInfo;
          }
        }
      }
      return {};
    },
    /**
     * 获取菜单
     */
    getloadMenuList() {
      getLoadMenu()
        .then(res => {
          if (res.retcode === 0) {
            localStorage.setItem("menuList", JSON.stringify(res.data));
            if (res.data.childList && res.data.childList.length > 0) {
              this.navList = res.data;
              this.showMenu = true;
              this.readyShowApp = true;
            } else {
              this.$alert("您没有访问该系统的权限", "提示", {
                confirmButtonText: "确定",
                showClose: false
              }).then(() => {
                this.go2Login();
              });
            }
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";
.tip {
  font-size: 12px;
}

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
  .app-main {
    height: calc(100vh - 50px);
    overflow: auto;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}

.breadcrumb-container {
  margin-left: 0px;
}
#app .main-container {
  margin-left: 0;
}
</style>
