/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-04-03 15:28:15
 * @LastEditors: gwb
 * @LastEditTime: 2023-07-28 11:59:26
 */
import store from "../store";
// operatorAction  0新增，1查询，2修改，3删除，4登录. 5导入. 6导出. 7下发
// 埋点接口

export const BurialPointFun = () => {
  const name = store.getters.getNameOrPhone;
  return {
    // "/api/v1/auth/login": {
    //   content: "【登录】了【通行管理】",
    //   operatorAction: 4
    // },
    "/pass-mgmt/v2/monitor/operate-log-page": {
      content: `${name}【查看】了【通行管理-工作台-操作日志-列表】`,
      operatorAction: 1,
    },
    "/pass-mgmt/v2/person/query-page-list": {
      content: `${name}【查看】了【通行管理-人员-人员信息-列表】`,
      operatorAction: 1,
    },
    "/pass-mgmt/v2/person/add": {
      content: `${name}【新增】了【通行管理-人员-人员信息】`,
      operatorAction: 0,
    },
    "/pass-mgmt/v2/person/edit": {
      content: `${name}【编辑】了【通行管理-人员-人员信息】`,
      operatorAction: 2,
    },
    "/pass-mgmt/v2/person/batch-update-floor-permission": {
      content: `${name}【编辑】了【通行管理-人员-人员信息-梯控权限】`,
      operatorAction: 2,
    },
    "/pass-mgmt/v2/person/batch-delete": {
      content: `${name}【删除】了【通行管理-人员-人员信息】`,
      operatorAction: 3,
    },
    [`/pass-mgmt/v2/person/${store.getters.projectId}/batch-import`]: {
      content: `${name}【导入】了【通行管理-人员-人员信息】`,
      operatorAction: 5,
    },
    "/pass-mgmt/v2/person/batch-export": {
      content: `${name}【导出】了【通行管理-人员-人员信息】`,
      operatorAction: 6,
    },
    "/pass-mgmt/v2/person-group/query-page-list-by-group": {
      content: `${name}【查看】了【通行管理-人员-人员分组-列表】`,
      operatorAction: 1,
    },
    "/pass-mgmt/v2/person-group/add": {
      content: `${name}【新增】了【通行管理-人员-人员分组】`,
      operatorAction: 0,
    },
    "/pass-mgmt/v2/person-group/delete": {
      content: `${name}【删除】了【通行管理-人员-人员分组】`,
      operatorAction: 3,
    },
    "/pass-mgmt/v2/person-group/edit": {
      content: `${name}【编辑】了【通行管理-人员-人员分组】`,
      operatorAction: 2,
    },
    "API/pass-mgmt/v2/person-group/batch-add-person": {
      content: `${name}【编辑】了【通行管理-人员-人员分组-批量加入】`,
      operatorAction: 2,
    },
    "/pass-mgmt/v2/person-group/batch-delete-person": {
      content: `${name}【删除】了【通行管理-人员-人员分组-批量移除】`,
      operatorAction: 3,
    },
    "/pass-mgmt/v2/device-group/query-page": {
      content: `${name}【查看】了【通行管理-设备-设备管理-列表】`,
      operatorAction: 1,
    },
    "/pass-mgmt/v2/device-group/detail": {
      content: `${name}【查看】了【通行管理-设备-设备分组-列表】`,
      operatorAction: 1,
    },
    "/pass-mgmt/v2/visit/bill-setting/save": {
      content: `${name}【编辑】了【通行管理-访客-访客单配置】`,
      operatorAction: 2,
    },
    "/pass-mgmt/v2/device-group/add": {
      content: `${name}【新增】了【通行管理-设备-设备分组】`,
      operatorAction: 0,
    },
    "/pass-mgmt/v2/device-group/delete": {
      content: `${name}【删除】了【通行管理-设备-设备分组】`,
      operatorAction: 3,
    },
    "/pass-mgmt/v2/device/query-page-device": {
      content: `${name}【查看】了【通行管理-设备管理-列表】`,
      operatorAction: 1,
    },
    "/pass-mgmt/v2/device-group/update": {
      content: `${name}【编辑】了【通行管理-设备-设备分组】`,
      operatorAction: 2,
    },
    "/pass-mgmt/v2/device-group/batch-add-device": {
      content: `${name}【编辑】了【通行管理-设备-设备分组-批量加入】`,
      operatorAction: 2,
    },
    "/pass-mgmt/v2/device-group/batch-delete-device": {
      content: `${name}【删除】了【通行管理-设备-设备分组-批量移除】`,
      operatorAction: 3,
    },
    "/pass-mgmt/v2/pass-record/query-page": {
      content: `${name}【查看】了【通行管理-通行-通行记录-列表】`,
      operatorAction: 1,
    },
    "/pass-mgmt/v2/pass-record/export": {
      content: `${name}【导出】了【通行管理-通行-通行记录-列表】`,
      operatorAction: 6,
    },
    "/pass-mgmt/v2/rule/get-page-rules": {
      content: `${name}【查看】了【通行管理-通行-通行规则-列表】`,
      operatorAction: 1,
    },
    "/pass-mgmt/v2/rule/add-rule": {
      content: `${name}【新增】了【通行管理-通行-通行规则】`,
      operatorAction: 0,
    },
    "/pass-mgmt/v2/rule/update-rule": {
      content: `${name}【编辑】了【通行管理-通行-通行规则】`,
      operatorAction: 2,
    },
    "/pass-mgmt/v2/rule/publish-batch-rules": {
      content: `${name}【下发】了【通行管理-通行-通行规则】`,
      operatorAction: 7,
    },
    "/pass-mgmt/v2/rule/delete-batch-rules": {
      content: `${name}【删除】了【通行管理-通行-通行规则】`,
      operatorAction: 3,
    },
    "/pass-mgmt/v2/visit/account/list-by-page": {
      content: `${name}【查看】了【通行管理-访客-前台设备授权-列表】`,
      operatorAction: 1,
    },
    "/pass-mgmt/v2/visit/account/device-auth": {
      content: `${name}【编辑】了【通行管理-访客-前台设备授权-设备授权】`,
      operatorAction: 2,
    },
    "/pass-mgmt/v2/visit/account/detail": {
      content: `${name}【查看】了【通行管理-访客-前台设备授权-详情】`,
      operatorAction: 1,
    },
    [`/pass-mgmt/v2/visit/bill-setting/${store.getters.projectId}`]: {
      content: `${name}【查看】了【通行管理-访客-访客单配置】`,
      operatorAction: 1,
    },
    "/ym-face/u/pass-mgmt/v2/enterprise/page": {
      content: `${name}【查看】了【通行管理-企业-企业管理-列表】`,
      operatorAction: 1,
    },
    "/pass-mgmt/v2/visit/account/detail": {
      content: `${name}【查看】了【通行管理-访客登记】`,
      operatorAction: 1,
    },
    "/pass-mgmt/v2/visit/bill/list-by-page": {
      content: `${name}【查看】了【通行管理-访客单管理-列表】`,
      operatorAction: 1,
    },
  };
};
