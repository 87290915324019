/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-03-27 10:53:44
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-05-16 16:43:20
 */
import MainLayout from "@/layout";
import EmptyLayoutQiankun from "@/components/EmptyLayoutQiankun.vue";
let Layout = MainLayout;
if (window.__POWERED_BY_QIANKUN__) {
  Layout = EmptyLayoutQiankun;
}

const ruleRouter = {
  path: "/rule",
  component: Layout,
  redirect: "noRedirect",
  meta: {
    title: "规则",
    icon: "rule",
  },
  children: [
    {
      path: "throughRules",
      component: () =>
        import(/* webpackChunkName: "rule" */ "@/views/rule/through"),
      name: "ThroughRules",
      meta: { title: "通行规则", noCache: true },
    },
  ],
};

export default ruleRouter;
