/*
 * @Author: gwb
 * @Date: 2023-07-24 11:14:45
 * @LastEditors: gwb
 * @LastEditTime: 2023-07-28 11:27:07
 * @Description:
 */
import axios from "axios";
import { Message, Loading } from "element-ui";
import { loginCacheClear } from "@/utils/auth";
import { createRouter } from "@/router/index";
import store from "@/store";
import { terrace } from "@/utils/constants";
import { BurialPointFun } from "@/utils/burialPointUrl";

let loadingInstance = null;
let reqNum = 0;
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_SERVER,
  timeout: 30000,
});

// 统一各平台接口返回的状态码的名称 retcode
function formateInterFaceState(response) {
  // 跳过为 0 的状态
  const retcode = response.data.retcode;
  const status = response.data.status;
  if (retcode === null || retcode === undefined) {
    if (status !== null && status !== undefined) {
      response.data.retcode = status;
    }
  } else {
    if (status === null || status === undefined) {
      response.data.status = retcode;
    }
  }
  return response;
}

// request interceptor
service.interceptors.request.use(
  (config) => {
    reqNum++;
    loadingInstance = Loading.service({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    config.headers["token"] = store.getters.token;

    config.headers["terrace"] = terrace;
    config.headers["projectId"] = localStorage.getItem("projectId") || "";
    if (config.url.match("export")) {
      config.responseType = "blob";
    }

    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    reqNum--;
    if (!reqNum) {
      loadingInstance && loadingInstance.close();
    }

    formateInterFaceState(response);
    if (response.config.url.indexOf("/api/v1/auth/logout") !== -1) {
      loginCacheClear();
      if (window.__POWERED_BY_QIANKUN__) {
        window.location.href = "/login";
      } else {
        createRouter().replace("/login");
      }
      return;
    }
    const res = response.data;
    if (response.config.url.indexOf("export") !== -1) {
      // 埋点
      addLog(response, "成功");
      return response;
    }
    if (res.status === 0 || res.retcode === 0) {
      // 埋点
      addLog(response, "成功");

      return res;
    } else if (res.retcode === 1012) {
      Message({
        message: "账号已在其他地方登录",
        type: "error",
        duration: 2 * 1000,
      });
      // loginCacheClear();
      // if (window.__POWERED_BY_QIANKUN__) {
      //   window.location.href = "/login";
      // } else {
      //   createRouter().replace("/login");
      // }
    } else if (
      res.retcode === 1001 ||
      res.retcode === 1011 ||
      res.status === 403
    ) {
      addLog(response, "失败");
      Message({
        message: res.msg || res.message,
        type: "error",
        duration: 2 * 1000,
      });
      // loginCacheClear();
      // if (window.__POWERED_BY_QIANKUN__) {
      //   window.location.href = "/login";
      // } else {
      //   createRouter().replace("/login");
      // }
    } else {
      addLog(response, "失败");
      Message({
        message: res.msg || res.message,
        type: "error",
        duration: 2 * 1000,
      });
      return Promise.reject(new Error(res.msg || res.message));
    }
  },
  (error) => {
    console.log("err" + error);
    loadingInstance && loadingInstance.close();
    addLog(error, "失败");
    Message({
      message: error.message,
      type: "error",
      duration: 2 * 1000,
    });
    return Promise.reject(error);
  }
);

//埋点
function addLog(response, resText) {
  const BurialPointObj = BurialPointFun();
  for (let key in BurialPointObj) {
    if (key === response.config.url) {
      const pramas = {
        ...BurialPointObj[key],
        operatorResult: resText,
      };
      // if (key === "/api/v1/auth/login") {
      //   const name =
      //     response.data.data.member.relaname ||
      //     response.data.data.member.mobile;
      //   //单独处理登录
      //   pramas.content = (name ? `【${name}】` : "") + pramas.content;
      //   //延时调用接口，储存token
      //   setTimeout(() => {
      //     store.dispatch("addLogs/addLog", pramas);
      //   }, 500);
      // } else {
      //   store.dispatch("addLogs/addLog", pramas);
      // }
      store.dispatch("addLogs/addLog", pramas);
    }
  }
}

export default service;
