<template>
  <div class="navbar">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />
    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
    <div class="top-select">
      <span style="font-size: 14px">当前管理空间：</span>
      <el-select v-model="currentSpaceId" @change="handleCurrentSpaceChange">
        <el-option
          v-for="item in treeData"
          :key="item.spaceId"
          :label="item.spaceName"
          :value="item.spaceId"
        />
      </el-select>
      <span style="position: relative; left: 10px; font-size: 14px">{{
        realName
      }}</span>
    </div>
    <div class="right-menu">
      <el-link type="primary" @click="logout">
        <span style="margin-right: 20px">退出</span>
      </el-link>
    </div>
  </div>
</template>

<script>
import emailMark from "@/assets/email.png";
import headimg from "@/assets/head-img.jpg";
import { mapGetters, mapState } from "vuex";
import Hamburger from "@/components/Hamburger";
import Breadcrumb from "@/components/Breadcrumb";
import { ssoLogout } from "@/api/user";
import { loginCacheClear } from "../../utils/auth";

export default {
  components: {
    Breadcrumb,
    Hamburger,
  },
  data() {
    return {
      headimg,
      emailMark,
      currentSpaceId: "",
      treeData: [],
      realName: "",
      spaceName: "",
    };
  },
  computed: {
    ...mapState({
      userTree: (state) => state.user.userTree,
    }),
    ...mapGetters(["sidebar", "avatar", "device"]),
  },
  methods: {
    handleCurrentSpaceChange(v) {
      if (v) {
        this.$store.dispatch("user/changeCurrentSpaceId", v);
        location.reload();
      }
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      this.$confirm("确定要退出该系统吗？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        showClose: false,
        type: "warning",
      })
        .then(() => {
          ssoLogout({})
            .then(() => {
              loginCacheClear();
              this.$router.push("/login");
            })
            .catch((error) => {
              loginCacheClear();
              this.$router.push("/login");
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  .top-select {
    position: absolute;
    right: 120px;
    top: 10px;
  }

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
