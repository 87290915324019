<template>
  <div>
    <div v-for="item of childList" :key="item.id">
      <template>
        <el-menu-item
          v-if="item.status === 1 && !item.childList"
          :key="item.id"
          :index="item.requestPath"
        >
          <i class="fa" :class="item.icon" />
          <span slot="title" class="tip">{{ item.name }}</span>
        </el-menu-item>
      </template>

      <el-submenu
        v-if="item.status === 1 && item.childList && item.childList.length > 0"
        :index="item.id + ''"
      >
        <template slot="title">
          <i class="fa" :class="item.icon" />
          <span class="tip">{{ item.name }}</span>
        </template>
        <vmenu :node="item" />
      </el-submenu>
    </div>
  </div>
</template>
<script>
export default {
  name: "Vmenu",
  props: {
    node: {
      type: Object,
      required: true,
      default: () => {}
    },
    noMenu: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  computed: {
    childList() {
      return this.node.childList || [];
    }
  }
};
</script>
<style lang="scss" scoped>
.fa {
  margin-right: 6px;
}
</style>
