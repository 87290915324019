/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-03-27 10:53:44
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-07-26 08:10:59
 */
import Vue from "vue";

import Cookies from "js-cookie";

import "./public-path.js";

import "normalize.css/normalize.css"; // a modern alternative to CSS resets

import Element from "element-ui";
import "./styles/element-variables.scss";

import "@/styles/index.scss"; // global css

import App from "./App";
import store from "./store";
import { createRouter, destroyRouter } from "./router";
import { getLoadMenu } from "@/api/loadMenu";
import "./icons"; // icon
// import "./permission"; // permission control
import "./utils/error-log"; // error log

import * as filters from "./filters"; // global filters
// import { message } from "ant-design-vue";

// message.config({
//   maxCount: 1
// });

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
import { mockXHR } from "../mock";
if (process.env.VUE_APP_NODE_ENV === "production") {
  mockXHR();
}

Vue.use(Element, {
  size: Cookies.get("size") || "medium", // set element-ui default size
});

// register global utility filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;

let instance = null;
let router;
function render(container) {
  router = createRouter();
  instance = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount(container ? container.querySelector("#main-app") : "#main-app");
}

if (!window.__POWERED_BY_QIANKUN__) {
  render();
}
export async function bootstrap() {}
export async function mount(props) {
  // 父子应用通信
  props.onGlobalStateChange((state) => {
    console.log(state, "父子应用通信");
    store.commit("qiankun/qiankunState", state);
  }, true);
  window.setQiankunState = props.setGlobalState.bind(props);
  render(props.container);
  // getLoadMenu().then(res => {
  //   if (res.retcode === 0) {
  setTimeout(() => {
    router.replace(location.hash);
  }, 500);

  //   }
  // });
}
export async function update(props) {
  console.log(props);
}
export async function unmount() {
  destroyRouter();
  instance.$destroy();
  instance = null;
}
