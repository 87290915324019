/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-05-16 18:23:34
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-05-16 18:25:30
 */
import MainLayout from "@/layout";
import EmptyLayoutQiankun from "@/components/EmptyLayoutQiankun.vue";
let Layout = MainLayout;
if (window.__POWERED_BY_QIANKUN__) {
  Layout = EmptyLayoutQiankun;
}

const personnelRouter = {
  path: "/personnel",
  component: Layout,
  redirect: "noRedirect",
  meta: {
    title: "人员",
    icon: "custom",
  },
  children: [
    {
      path: "persInfo",
      component: () =>
        import(
          /* webpackChunkName: "personnel" */ "@/views/personnel/persInfo"
        ),
      name: "Personnel",
      meta: { title: "人员管理", noCache: true },
    },
    {
      path: "personnelgroup",
      component: () =>
        import(
          /* webpackChunkName: "personnel" */ "@/views/personnel/personnelGroup"
        ),
      name: "PersonnelGroup",
      meta: { title: "人员分组", noCache: true },
    },
    {
      path: "personnelimport",
      component: () =>
        import(
          /* webpackChunkName: "personnel" */ "@/views/personnel/persInfo/components/import"
        ),
      name: "personnelImport",
      meta: { title: "人员导入", noCache: true },
    },
  ],
};

export default personnelRouter;
