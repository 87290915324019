/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-03-27 10:53:44
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-05-16 18:07:40
 */
import Vue from "vue";
import Router from "vue-router";
/* Layout */
import layout from "@/layout";
import horizontal from "@/layout/horizontal";
import personnel from "./modules/personnel";
import deviceMana from "./modules/deviceMana";
import rule from "./modules/rule";
import record from "./modules/record";
import businessRouter from "./modules/business";
import attence from "./modules/attence";
import accessCardRouter from "./modules/accessCard";

Vue.use(Router);

export const asyncRoutes = [{ path: "*", redirect: "/404", hidden: true }];

let routerInstance = new Router({
  scrollBehavior: () => ({ y: 0 }),
  base: window.__POWERED_BY_QIANKUN__
    ? "/" + process.env.VUE_APP_SUBAPPNAME
    : process.env.BASE_URL,
  routes: constantRoutes(),
});

export function constantRoutes() {
  let Layout =
    sessionStorage.getItem("layoutType") === "horizontal" ? horizontal : layout;

  return [
    {
      path: "/login",
      component: () => import("@/views/login/index"),
      hidden: true,
    },
    {
      path: "/auth-redirect",
      component: () => import("@/views/login/auth-redirect"),
      hidden: true,
    },
    {
      path: "/404",
      component: () => import("@/views/error-page/404"),
      hidden: true,
    },
    {
      path: "/401",
      component: Layout,
      children: [
        {
          path: "/401",
          component: () => import("@/views/error-page/401"),
        },
      ],
    },
    {
      path: "/",
      component: Layout,
      redirect: "/dashboard",
      children: [
        {
          path: "dashboard",
          component: () =>
            import(/* webpackChunkName: "dashboard" */ "@/views/dashboard/"),
          name: "Dashboard",
          meta: { title: "首页", affix: true },
        },
        {
          path: "operationLog",
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ "@/views/dashboard/operationLog"
            ),
          name: "OperationLog",
          meta: { title: "操作日志", noCache: true },
        },
      ],
    },
    personnel,
    deviceMana,
    rule,
    record,
    attence(),
    businessRouter,
    accessCardRouter,
  ];
}

export function createRouter() {
  if (!routerInstance) {
    routerInstance = new Router({
      scrollBehavior: () => ({ y: 0 }),
      base: window.__POWERED_BY_QIANKUN__
        ? "/" + process.env.VUE_APP_SUBAPPNAME
        : process.env.BASE_URL,
      routes: constantRoutes(),
    });
  }
  return routerInstance;
}

export function resetRouter() {
  const newRouter = createRouter();
  routerInstance.matcher = newRouter.matcher; // reset router
}

export function destroyRouter() {
  routerInstance = null;
}
