/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-05-16 18:23:34
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-05-16 18:25:54
 */
import horizontal from "@/layout/horizontal";
import MainLayout from "@/layout";
import EmptyLayoutQiankun from "@/components/EmptyLayoutQiankun.vue";
let layout = MainLayout;
if (window.__POWERED_BY_QIANKUN__) {
  layout = EmptyLayoutQiankun;
}

let LayoutType =
  sessionStorage.getItem("layoutType") === "horizontal" ? horizontal : layout;

const attenceRouter = function() {
  return {
    path: "/access",
    component: LayoutType,
    redirect: "noRedirect",
    meta: {
      title: "",
      icon: "attence",
      keepAlive: false
    },
    children: [
      {
        path: "accessOrderManagement",
        component: () =>
          import(/* webpackChunkName: "access" */ "@/views/access/accessOrderManagement"),
        name: "accessOrderManagement",
        meta: { title: "访问单管理", noCache: true }
      },
      {
        path: "visitorInvitationManagement",
        component: () =>
          import(/* webpackChunkName: "access" */ "@/views/access/visitorInvitationManagement"),
        name: "visitorInvitationManagement",
        meta: { title: "访客邀请管理", noCache: true }
      },
      {
        path: "visitorRegistration",
        component: () =>
          import(/* webpackChunkName: "access" */ "@/views/access/visitorRegistration"),
        name: "visitorRegistration",
        meta: { title: "访客登记", noCache: true }
      },
      {
        path: "import",
        component: () =>
          import(/* webpackChunkName: "access" */ "@/views/access/visitorRegistration/components/import"),
        name: "import",
        meta: { title: "导入", noCache: true }
      }
    ]
  };
};

export default attenceRouter;
