/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2022-08-01 17:50:15
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-03-30 17:25:00
 */
import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";

Vue.use(Vuex);

if (window.__POWERED_BY_QIANKUN__) {
  const Storage = require("./storage").default;
  Storage.install();
  console.info("子应用 localstorage、sessionstorage 已隔离");
}

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context("./modules", true, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  // console.log('modules: ', modules)
  return modules;
}, {});

const store = new Vuex.Store({
  modules,
  getters
});

export default store;
