//证件类型
export let cardTypeList = [
	{
		value: 0,
		name: "身份证",
	},
	{
		value: 1,
		name: "护照",
	},
	{
		value: 2,
		name: "学生证",
	},
	{
		value: 3,
		name: "外国人永久居留证",
	},
	{
		value: 4,
		name: "军人证",
	},
	{
		value: 5,
		name: "台胞证",
	},
	{
		value: 6,
		name: "台湾通行证",
	},
	{
		value: 7,
		name: "港澳通行证",
	},
	{
		value: 8,
		name: "港澳居民居住证",
	},
	{
		value: 9,
		name: "台湾居民居住证",
	},
	{
		value: 10,
		name: "其他证件",
	}];

