import Cookies from "js-cookie";
import crypto from "crypto";

/**
 *  本地缓存KEY
 */
export const ssoCacheKey = {
  rootSpaceId: "rootSpaceId", // 根空间
  currentSpaceId: "currentSpaceId", // 当前空间
  faceInfo: "faceInfo",
  userInfo: "userInfo",
  projectSpaceTree: "projectSpaceTree",
  userToken: "userToken"
};

export function envInfos() {
  return {
    tokenKey: process.env.VUE_APP_TOKEN_KEY,
    domain: process.env.VUE_APP_DOMAIN,
    currentSpaceId: process.env.VUE_APP_CUR_SPACEID,
    userInfo: process.env.VUE_APP_USERINFO
  };
}

export function getToken() {
  const envInfo = envInfos();
  return Cookies.get(envInfo.tokenKey, { domain: envInfo.domain });
}

export function setToken(token) {
  const envInfo = envInfos();
  return Cookies.set(envInfo.tokenKey, token, { domain: envInfo.domain });
}

export function setUseInfo(userInfo) {
  const envInfo = envInfos();
  return Cookies.set(envInfo.userInfo, userInfo, { domain: envInfo.domain });
}

export function removeToken() {
  const envInfo = envInfos();
  return Cookies.remove(envInfo.tokenKey, { domain: envInfo.domain });
}

export function setCookies(key, value) {
  const envInfo = envInfos();
  return Cookies.set(key, value, { domain: envInfo.domain });
}

export function getCookies(key) {
  const envInfo = envInfos();
  if (ssoCacheKey.currentSpaceId === key) {
    key = envInfo.currentSpaceId;
  }
  if (ssoCacheKey.userInfo === key) {
    key = envInfo.userInfo;
  }

  return Cookies.get(key, { domain: envInfo.domain });
}

export function removeCookies(key) {
  return Cookies.remove(key, { domain: envInfo.domain });
}

// 选择及当前的spaceId
export function getCurrentSpaceId() {
  return Number.parseInt(getLocalStorage(ssoCacheKey.currentSpaceId));
}

export function ssoCacheClear() {
  localStorage.removeItem(ssoCacheKey.rootSpaceId);
  localStorage.removeItem(ssoCacheKey.faceInfo);
  localStorage.removeItem(ssoCacheKey.userInfo);
}

export function loginCacheClear() {
  removeToken();
  const envInfo = envInfos();
  Cookies.remove(envInfo.currentSpaceId, { domain: envInfo.domain });
  Cookies.remove(envInfo.userInfo, { domain: envInfo.domain });
  localStorage.removeItem(ssoCacheKey.rootSpaceId);
  localStorage.removeItem(ssoCacheKey.faceInfo);
  localStorage.removeItem(ssoCacheKey.userInfo);
  localStorage.removeItem("projectId");
  localStorage.removeItem("menuList");
  sessionStorage.removeItem("layoutType");
  localStorage.clear();
}

export function getLocalStorage(key) {
  return localStorage.getItem(key);
}

export function setLocalStorage(key, value) {
  const envInfo = envInfos();
  return localStorage.setItem(key, value);
}

export function removeLocalStorage(key) {
  return localStorage.removeItem(key);
}

export function md5Encryption(data) {
  const md5 = crypto.createHash("md5");
  md5.update(data);
  return md5.digest("hex");
}
