/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2022-08-01 17:50:15
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-07-26 10:08:18
 */
import request from "@/utils/request";
export function ssoLogin(data) {
  return request({
    url: "/api/v1/auth/login",
    method: "post",
    data,
  });
}

export function ssoInfo() {
  return request({
    url: "/ym-face/u/pass-mgmt/v2/user/init",
    method: "post",
  });
}

export function ssoLogout() {
  return request({
    url: "/api/v1/auth/logout",
    method: "post",
  });
}
