<!--
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-03-28 15:22:44
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-03-30 17:30:30
-->
<template>
  <router-view v-if="forceReloade"></router-view>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "EmptyLayout",
  data() {
    return {
      forceReloade: true
    };
  },
  provide() {
    return {
      reload: this.reload
    };
  },
  computed: {
    ...mapGetters(["projectId", "currentSpaceId", "userInfo"])
  },
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        console.log(val)
        this.reload && this.reload();
        localStorage.setItem("projectId", val);
      }
    },
    currentSpaceId: {
      immediate: true,
      handler(val) {
        localStorage.setItem("currentSpaceId", val);
      }
    },
    userInfo: {
      immediate: true,
      handler(val) {
        localStorage.setItem("userInfo", JSON.stringify(val));
      }
    }
  },
  methods: {
    reload() {
      this.forceReloade = false;
      this.$nextTick(() => {
        this.forceReloade = true;
      });
    }
  }
};
</script>
