<template>
  <div :class="classObj" class="app-wrapper">
    <div
      v-if="device === 'mobile' && sidebar.opened"
      class="drawer-bg"
      @click="handleClickOutside"
    />

    <sideBarTwo
      v-if="showMenu"
      :navList="navList"
      class="sidebar-container"
      @noExtent="noExtent"
    />

    <div :class="{ hasTagsView: needTagsView }" class="main-container">
      <div :class="{ 'fixed-header': fixedHeader }">
        <Navbar ref="navBarRef" />
      </div>

      <app-main v-if="readyShowApp" />

      <right-panel v-if="showSettings">
        <settings />
      </right-panel>
    </div>

    <el-dialog
      title="提示"
      :visible.sync="no_dialog"
      width="30%"
      :show-close="false"
    >
      <div>您没有访问该系统的权限</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="go2Login">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import RightPanel from "@/components/RightPanel";
import { AppMain, Navbar } from "./components";
import sideBarTwo from "./components/SideBarTwo/index";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from "vuex";
import { getLoadMenu } from "@/api/loadMenu";
import { getToken, envInfos } from "@/utils/auth"; // get token from cookie

import {
  setLocalStorage,
  getLocalStorage,
  ssoCacheKey,
  getCurrentSpaceId,
  getCookies,
  ssoCacheClear,
  loginCacheClear,
} from "@/utils/auth";
import { ssoInfo } from "../api/user";
import { terrace } from "../utils/constants";
export default {
  inject: ["reload"],
  name: "Layout",
  components: {
    AppMain,
    Navbar,
    RightPanel,
    sideBarTwo,
  },
  mixins: [ResizeMixin],
  data() {
    return {
      readyShowApp: false,
      value: "",
      projectSpaceTree: "",
      selCurrentSpaceId: "",
      showMenu: false,
      no_dialog: false,
      navList: {},
    };
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      device: (state) => state.app.device,
      showSettings: (state) => state.settings.showSettings,
      needTagsView: (state) => state.settings.tagsView,
      fixedHeader: (state) => state.settings.fixedHeader,
      currentSpaceId: (state) => state.user.currentSpaceId + "",
      userTree: (state) => state.user.userTree,
      token: (state) => state.user.token,
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
  },
  mounted() {
    if (window.location.href.indexOf("from=iot-platform") !== -1) {
      //物联云跳转过来，获取物联云的spaceId和token,并存在localStorage里
      console.log("from-iot");
      const envInfo = envInfos();
      const ssoCurrentSpaceId = getCookies(envInfo.currentSpaceId);
      const ssoUserToken = getToken(envInfo.tokenKey);
      this.selCurrentSpaceId = ssoCurrentSpaceId;
      if (ssoCurrentSpaceId && ssoUserToken) {
        setLocalStorage(ssoCacheKey.currentSpaceId, ssoCurrentSpaceId);
        setLocalStorage(ssoCacheKey.userToken, ssoUserToken);

        this.getUserTree();
      } else {
        this.$router.push("/login");
      }
      return;
    }

    //刷新页面或者切换项目时如下
    if (!this.token) {
      loginCacheClear();
      this.$router.push("/login");
    }
    let spaceName = this.$refs.navBarRef.spaceName; //防止多次调用方法
    if (spaceName) {
      return;
    } else if (this.token) {
      this.getUserTree();
    }
  },
  methods: {
    go2Login() {
      this.no_dialog = false;
      loginCacheClear();
      this.$router.push("/login");
    },
    noExtent(e) {
      this.no_dialog = e;
    },
    handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", { withoutAnimation: false });
    },
    initSsoSpaceInfo() {
      const envInfo = envInfos();
      const ssoUserInfo = getCookies(envInfo.userInfo);
      const currentSpaceId = getCurrentSpaceId(); //需要从localStorage拿
      this.selCurrentSpaceId = currentSpaceId;
      console.log(ssoUserInfo, currentSpaceId, 999999999);
      if (!ssoUserInfo || !currentSpaceId) {
        this.go2Login();
        return;
      }
      try {
        const ssoMember = JSON.parse(ssoUserInfo);
        setLocalStorage(ssoCacheKey.userInfo, JSON.stringify(ssoMember));
        this.$refs.navBarRef.realName = ssoMember.relaname;
        const projectList = this.projectSpaceTree;
        const spaceInfo = this.getSelCurrentSpaceInfo(
          projectList,
          currentSpaceId
        );
        this.setCurrentSpaceInfo(projectList, spaceInfo);
      } catch (e) {}
    },
    /**
     * 设置当前空间信息
     */
    setCurrentSpaceInfo(projectList, spaceInfo) {
      if (!projectList && !projectList.length) {
        this.go2Login();
        return;
      }
      setLocalStorage(
        ssoCacheKey.projectSpaceTree,
        JSON.stringify(projectList)
      );
      this.$refs.navBarRef.treeData = projectList;
      if (spaceInfo && spaceInfo.selSpaceId) {
        this.$store.dispatch("user/changeCurrentSpaceId", spaceInfo.selSpaceId);
        setLocalStorage(ssoCacheKey.currentSpaceId, spaceInfo.selSpaceId);
        setLocalStorage(ssoCacheKey.rootSpaceId, spaceInfo.rootSpaceId);
        setLocalStorage(
          ssoCacheKey.faceInfo,
          JSON.stringify(spaceInfo.selProjectInfo)
        );
        this.$refs.navBarRef.currentSpaceId = spaceInfo.selSpaceId;
        this.$refs.navBarRef.spaceName = spaceInfo.selSpaceName;
        this.getloadMenuList();
        return;
      }
    },
    /**
     * 获取当前空间信息
     */
    getSelCurrentSpaceInfo(projectList, currentSpaceId) {
      if (currentSpaceId) {
        for (let i = 0; i < projectList.length; i++) {
          const projectInfo = projectList[i];
          if (currentSpaceId == projectInfo.spaceId) {
            const spaceInfo = {};
            spaceInfo.selProjectInfo = projectInfo;
            spaceInfo.selSpaceName = projectInfo.spaceName;
            spaceInfo.selSpaceId = currentSpaceId;
            spaceInfo.rootSpaceId = projectInfo.rootId;
            setLocalStorage("projectId", projectInfo.projectId);
            setLocalStorage(
              "dredgeLiftControlServer",
              projectInfo.dredgeLiftControlServer
            );
            return spaceInfo;
          }
        }
      }
      return {};
    },
    /**
     * 获取菜单
     */
    getloadMenuList() {
      getLoadMenu()
        .then((res) => {
          if (res.retcode === 0) {
            localStorage.setItem("menuList", JSON.stringify(res.data));
            if (res.data.childList && res.data.childList.length > 0) {
              this.navList = res.data;
              this.showMenu = true;
              this.readyShowApp = true;
            } else {
              this.$alert("您没有访问该系统的权限", "提示", {
                confirmButtonText: "确定",
                showClose: false,
              }).then(() => {
                this.go2Login();
              });
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getUserTree() {
      await ssoInfo().then((res) => {
        if (res.retcode !== 0) {
          this.$message({
            message: "加载空间信息失败！",
            type: "error",
            duration: 2 * 1000,
          });
          return;
        }
        const { data } = res;

        if (data.length === 0) {
          this.$message({
            message: "请配置用户空间后再登录",
            type: "error",
            duration: 2 * 1000,
          });
          loginCacheClear();
          this.$router.push("/login");
        }
        const projectList = [];
        for (let i = 0; i < data.length; i++) {
          const dataInfo = data[i];
          const servers = dataInfo.servers;
          if (!servers || servers.length === 0) {
            projectList.push({
              spaceId: dataInfo.spaceId,
              spaceName: dataInfo.name,
              rootId: dataInfo.spaceId,
              projectId: dataInfo.projectId,
              isAdmin: dataInfo.admin,
            });
            console.log("servers is null, detail:", dataInfo);
            continue;
          }
          const servesInfo = servers.find((item) => {
            return item.code === terrace;
          });
          if (!servesInfo) {
            console.log("servers code face not exist, detail:", dataInfo);
            this.$message.error("账号没有通行权限");
            continue;
          }

          projectList.push({
            spaceId: dataInfo.spaceId,
            spaceName: dataInfo.name,
            rootId: dataInfo.spaceId,
            projectId: dataInfo.projectId,
            isAdmin: dataInfo.admin,
          });
        }

        this.projectSpaceTree = projectList;
        if (projectList.length) {
          setLocalStorage(
            ssoCacheKey.projectSpaceTree,
            JSON.stringify(projectList)
          );
          this.initSsoSpaceInfo();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";
.tip {
  font-size: 12px;
}

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}

.breadcrumb-container {
  margin-left: 0px;
}
</style>
