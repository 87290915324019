/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2022-08-01 17:50:15
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-05-16 16:43:05
 */
import MainLayout from "@/layout";
import EmptyLayoutQiankun from "@/components/EmptyLayoutQiankun.vue";
let Layout = MainLayout;
if (window.__POWERED_BY_QIANKUN__) {
  Layout = EmptyLayoutQiankun;
}

const deviceManaRouter = {
  path: "/deviceMana",
  component: Layout,
  redirect: {
    name: "DeviceMana",
  },
  meta: {
    title: "设备",
    icon: "platform",
  },
  children: [
    {
      path: "deviceMana",
      component: () =>
        import(
          /* webpackChunkName: "deviceMana" */ "@/views/device/deviceMana"
        ),
      name: "DeviceMana",
      meta: { title: "设备管理", noCache: true },
    },
    {
      path: "deviceGroup",
      component: () =>
        import(
          /* webpackChunkName: "deviceMana" */ "@/views/device/deviceGroup"
        ),
      name: "deviceGroup",
      meta: { title: "设备分组", noCache: true },
    },
  ],
};

export default deviceManaRouter;
