// import parseTime, formatTime and set to filter
export { parseTime, formatTime } from "@/utils";
import { cardTypeList } from "@/conf/index";

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + "s";
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), " minute");
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), " hour");
  } else {
    return pluralize(~~(between / 86400), " day");
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1e18, symbol: "E" },
    { value: 1e15, symbol: "P" },
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "G" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "k" }
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[i].symbol
      );
    }
  }
  return num.toString();
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0)
    .toString()
    .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function ruleStatus(status, isDelete) {
  // 0、未下发[1,1]，1、下发中[0,1]， 2、下发成功[0,1] 3、下发失败[1,1]
  if (isDelete === 0) {
    const RuleStatus = ["未下发", "下发中", "下发成功", "下发失败"];
    return RuleStatus[status];
  } else {
    const sc = ["未删除", "已删除", "删除中"];
    return sc[isDelete];
  }
}

// 性别过滤
export function sexFilter(n) {
  if (n === 0) {
    return "男";
  } else if (n === 1) {
    return "女";
  } else {
    return "未知";
  }
}

// 人员类型
export function peopleTypeFilter(id) {
  // 人脸平台只会有学生和宿管这两个
  const n = Number.parseInt(id);
  if (n === 0) {
    return "学生";
  } else if (n === 1) {
    return "宿管";
  } else if (n === 2) {
    return "老师";
  } else if (n === 3) {
    return "业主";
  } else if (n === 4) {
    return "租客";
  } else if (n === 5) {
    return "物业";
  } else if (n === 6) {
    return "经理";
  } else if (n === 7) {
    return "客人";
  } else if (n === 8) {
    return "游客";
  } else if (n === 99) {
    return "陌生人";
  } else {
    return "未知";
  }
}

// 通行方式
export function transitStatusType(n) {
  if (n === 0) {
    return "正常";
  } else if (n === 1) {
    return "闯入";
  } else if (n === 2) {
    return "晚归";
  } else if (n === 3) {
    return "超时";
  } else {
    return "";
  }
}

// 电量
export function electricQuantityFilter(n) {
  if (n == null) {
    return "";
  }
}

// 通行许可
export function permitType(n) {
  const type = ["不允许", "允许"];
  return type[n];
}

// 设备状态
export function deviceStatusFilter(n) {
  if (n === 0) {
    return "失效";
  } else if (n === 1) {
    return "预安装";
  } else if (n === 2) {
    return "已安装";
  } else if (n === 3) {
    return "在线";
  } else if (n === 4) {
    return "离线";
  } else {
    return "";
  }
}

// 考勤状态
export function attendanceType(n) {
  if (n === 1) {
    return "久出未归";
  } else {
    return "长期未出";
  }
}

// 考勤处理状态
export function attendanceHandleType(n) {
  if (n === 0) {
    return "已处理";
  } else {
    return "未处理";
  }
}

// 有效星期
export function effectiveWeekFilter(str) {
  const ffectiveWeekArr = [
    "",
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
    "星期日"
  ];
  const effectiveWeek = [];
  if (str) {
    str.split(",").forEach(item => {
      effectiveWeek.push(ffectiveWeekArr[item]);
    });
  }
  return effectiveWeek.join(",");
}

// 卡状态
export function cardStatusFilter(n) {
  if (n === "1" || n === 1) {
    return "有效";
  } else {
    return "无效";
  }
}

// 楼层格式化
export function floorFormat(n) {
  if (n) {
    const newArr = n.split(",");
    const res = [];
    newArr.map(item => {
      res.push(item + "F");
    });
    return res.join(", ");
  } else {
    return "";
  }
}

// 邀约权限
export function visitorPermissionFilter(n) {
  if (n === 0) {
    return "无";
  } else {
    return "有";
  }
}

// 证件类型
export function certificateTypeFilter(n) {
  let obj = {};
  cardTypeList.forEach(v => {
    obj[v.value] = v.name;
  });
  let val = Number(n);
  return n != null ? obj[val] : "";
}
