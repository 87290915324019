<template>
  <div class="sidebar">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-menu
      mode="horizontal"
      :router="true"
      :default-active="$route.path"
      :text-color="variables.menuText"
      :unique-opened="sidebar.opened"
      :background-color="variables.menuBg2"
      :active-text-color="variables.menuActiveText"
    >
      <vmenu
        v-if="navList && navList.childList && navList.childList.length > 0"
        :node="navList"
      />
      <div v-if="noMenu" class="defalut-menu">
        <div v-for="item in 6" :key="item" />
      </div>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import vmenu from "./components/vmenu";
import variables from "@/styles/variables.scss";
import Logo from "./components/Logo";

export default {
  name: "SideBarTwo",
  components: {
    vmenu,
    Logo,
  },
  data() {
    return {
      centerDialogVisible: false,
      noMenu: false,
    };
  },
  props:{
    navList: {
      type: Object,
      deep: true
    }
  },
  watch:{
    navList(n){
    }
  },
  computed: {
    ...mapGetters(["sidebar"]),
    isCollapse() {
      const opened = !this.sidebar.opened;
      return opened;
    },
    variables() {
      return variables;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.el-menu--horizontal {
  display: inline-block;
}
::v-deep.el-menu-item.is-active {
  color: rgba(0, 128, 255, 1) !important;
}
.fa {
  margin-right: 6px;
}

.sidebar {
  .appName {
    font-weight: bolder;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .el-menu {
    max-width: calc(100% - 206px);
  }
}
::v-deep .tip {
  background-color: transparent;
  border-left: none;
}

::v-deep .el-menu--collapse {
  .el-submenu {
    .el-submenu__title {
      position: relative;

      i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.defalut-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}
.defalut-menu div {
  width: 180px;
  height: 24px;
  margin-bottom: 24px;
  background: rgba(255, 255, 255, 0.16);
}
</style>
