/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-05-16 18:23:34
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-05-16 18:26:08
 */
import MainLayout from "@/layout";
import EmptyLayoutQiankun from "@/components/EmptyLayoutQiankun.vue";
let Layout = MainLayout;
if (window.__POWERED_BY_QIANKUN__) {
  Layout = EmptyLayoutQiankun;
}

const recordRouter = {
  path: "/record",
  component: Layout,
  redirect: "noRedirect",
  meta: {
    title: "记录",
    icon: "record",
  },
  children: [
    {
      path: "trafficRecord",
      component: () =>
        import(/* webpackChunkName: "record" */ "@/views/record/trafficRecord"),
      name: "trafficRecord",
      meta: { title: "通行记录", noCache: true },
    },
  ],
};

export default recordRouter;
