/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-03-30 17:25:09
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-03-30 17:57:37
 */
// @ts-nocheck
export default class Storage {
  #namespace = "";
  #storage;
  #length = 0;
  /**
   * 构造函数
   * @param {"localStorage|sessionStorage"} type 存储类型 默认 localStorage
   * @param {String} namespace 域名空间 默认读取环境变量VUE_APP_SUBAPPNAME
   */
  constructor(type, namespace) {
    namespace = namespace || process.env.VUE_APP_SUBAPPNAME;
    console.log(222, "face-v2 namespace:", namespace);
    if (["localStorage", "sessionStorage"].indexOf(type) === -1) {
      throw new Error("type 必须是 localStorage|sessionStorage");
    } else if (!namespace) {
      throw new Error("请传入namespace或者在环境变量中配置VUE_APP_SUBAPPNAME");
    }
    if (window[type] instanceof Storage) {
      window[type].update(namespace);
      return window[type];
    }
    this.#storage = window[type];
    this.update(namespace);
  }
  get length() {
    return this.#length;
  }
  getItem(key) {
    return this.#storage.getItem(this.#namespace + key);
  }
  setItem(key, value) {
    if (key) {
      if (typeof value !== "string") {
        value = JSON.stringify(value);
      }
      if (!this.getItem(key)) {
        this.#length++;
      }
      this.#storage.setItem(this.#namespace + key, value);
    }
  }
  removeItem(key) {
    if (this.getItem(key) !== null) {
      this.#length--;
    }
    this.#storage.removeItem(this.#namespace + key);
  }
  clear() {
    const storage = this.#storage;
    if (this.#length === storage.length) {
      storage.clear();
    } else {
      const namespace = this.#namespace;
      for (let i = 0, l = storage.length, k; i < l; i++) {
        k = storage.key(i);
        if (k && k.indexOf(namespace) === 0) {
          storage.removeItem(k);
        }
      }
    }
    this.#length = 0;
  }
  /**
   * 更新 namespace
   * @param {String} namespace
   */
  update(namespace) {
    namespace = namespace + "_";
    if (namespace !== this.#namespace) {
      this.#namespace = namespace + "_";
      const storage = this.#storage;
      namespace = this.#namespace;
      for (let i = 0, l = storage.length, k; i < l; i++) {
        k = storage.key(i);
        if (k && k.indexOf(namespace) === 0) {
          this.#length++;
        }
      }
    }
  }
  /**
   * 调用后会同时初始化localStorage和sessionStorage
   * 调用前确保这两个storage是可读写的（主应用已处理）
   */
  static install() {
    if (!window.localStorage instanceof Storage) {
      window.localStorage = new Storage("localStorage");
      window.sessionStorage = new Storage("sessionStorage");
    }
  }
}
