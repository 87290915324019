/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-04-03 16:32:31
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-04-03 16:32:56
 */
import { sysLog } from "@/api/logs";

const state = {};

const actions = {
  // 添加日志
  addLog({ commit }, params) {
    sysLog({
      ...params,
      operatorTerminalType: 0,
      platfrom: process.env.VUE_APP_TERRACE,
    })
      // .then((res) => {
      //   // console.log(res);
      // })
      .catch((error) => {
        console.log(error);
      });
  },
};

export default {
  namespaced: true,
  state,
  actions,
};
