/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-04-03 13:34:53
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-07-26 10:08:13
 */
import request from "@/utils/request";
export function sysLog(parameter) {
  return request({
    url: `/ym-business-point-data/u/point-data/v2/sys-log/add`,
    method: "post",
    data: parameter,
  });
}
