import { ssoLogin, ssoLogout } from "@/api/user";
import {
  getToken,
  setToken,
  setUseInfo,
  getCurrentSpaceId,
  loginCacheClear,
  md5Encryption,
  setLocalStorage,
  ssoCacheKey,
} from "@/utils/auth";
import { createRouter, resetRouter } from "@/router";

const state = {
  token: getToken(),
  name: "",
  avatar: "",
  currentSpaceId: getCurrentSpaceId(),
  introduction: "",
  roles: [],
  cameraSuccess: "",
  projectId: "",
  userInfo: {},
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_PROJECT: (state, id) => {
    state.projectId = id;
  },
  SET_SPACEID: (state, id) => {
    state.currentSpaceId = id;
  },
  SET_USER: (state, user) => {
    state.userInfo = user;
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_CURRENT_SPACEID: (state, currentSpaceId) => {
    state.currentSpaceId = currentSpaceId;
  },
  SET_CAMERA_SUCCESS: (state, url) => {
    state.cameraSuccess = url;
  },
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { mobile, password } = userInfo;
    return new Promise((resolve, reject) => {
      const formData = {
        mobile,
        password: md5Encryption(password),
        loginType: 0,
      };
      ssoLogin(formData)
        .then((res) => {
          const { data } = res;
          setLocalStorage(ssoCacheKey.userInfo, JSON.stringify(data.member));
          commit("SET_TOKEN", data.token.token);
          commit("SET_USER", data.member);
          setToken(data.token.token);
          setUseInfo(data.member);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      const data = {
        roles: ["admin"],
        introduction: "I am a super administrator",
        avatar:
          "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif",
        name: "Super Admin",
      };

      if (!data) {
        reject("Verification failed, please Login again.");
      }

      const { roles, name, avatar, introduction } = data;

      // roles must be a non-empty array
      if (!roles || roles.length <= 0) {
        reject("getInfo: roles must be a non-null array!");
      }

      commit("SET_ROLES", roles);
      commit("SET_NAME", name);
      commit("SET_AVATAR", avatar);
      commit("SET_INTRODUCTION", introduction);
      resolve(data);
    });
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      ssoLogout(state.token)
        .then(() => {
          commit("SET_TOKEN", "");
          commit("SET_ROLES", []);
          commit("SET_CURRENT_SPACEID", "");
          loginCacheClear();
          resetRouter();
          dispatch("tagsView/delAllViews", null, { root: true });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      commit("SET_ROLES", []);
      resolve();
    });
  },

  // dynamically modify permissions
  changeRoles({ commit, dispatch }, role) {
    return new Promise(async (resolve) => {
      const token = role + "-token";

      commit("SET_TOKEN", token);
      setToken(token);

      const { roles } = await dispatch("getInfo");

      resetRouter();

      // generate accessible routes map based on roles
      const accessRoutes = await dispatch("permission/generateRoutes", roles, {
        root: true,
      });

      // dynamically add accessible routes
      createRouter().addRoutes(accessRoutes);

      // reset visited views and cached views
      dispatch("tagsView/delAllViews", null, { root: true });

      resolve();
    });
  },

  // SET_CURRENT_SPACEID
  changeCurrentSpaceId({ commit }, currentSpaceId) {
    setLocalStorage(ssoCacheKey.currentSpaceId, currentSpaceId);
    commit("SET_CURRENT_SPACEID", currentSpaceId);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
