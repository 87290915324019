const tokens = {
  admin: {
    token: "admin-token"
  },
  editor: {
    token: "editor-token"
  }
};

const users = {
  "admin-token": {
    roles: ["admin"],
    introduction: "I am a super administrator",
    avatar:
      "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif",
    name: "Super Admin"
  },
  "editor-token": {
    roles: ["editor"],
    introduction: "I am an editor",
    avatar:
      "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif",
    name: "Normal Editor"
  }
};

export default [
  // user login
  {
    url: "/user/login",
    type: "post",
    response: config => {
      const { mobile } = config.body;
      const token = tokens[mobile];

      // mock error
      if (!token) {
        return {
          status: 0,
          data: {
            roles: ["admin"],
            introduction: "I am a super administrator",
            avatar:
              "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif",
            name: "Super Admin"
          }
        };
      }

      return {
        status: 0,
        data: token
      };
    }
  },

  // get user info
  {
    url: "/user/info.*",
    type: "get",
    response: config => {
      // console.log('config: ', config)
      const { token } = config.query;
      const info = users[token];

      // mock error
      if (!info) {
        return {
          status: 0,
          data: {
            roles: ["admin"],
            introduction: "I am a super administrator",
            avatar:
              "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif",
            name: "Super Admin"
          }
        };
      }

      return {
        status: 0,
        data: info
      };
    }
  },

  // user logout
  {
    url: "/user/logout",
    type: "post",
    response: _ => {
      return {
        status: 0,
        data: "success"
      };
    }
  }
];
