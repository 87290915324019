/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-03-27 10:53:44
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-05-16 16:42:56
 */
import MainLayout from "@/layout";
import EmptyLayoutQiankun from "@/components/EmptyLayoutQiankun.vue";
let Layout = MainLayout;
if (window.__POWERED_BY_QIANKUN__) {
  Layout = EmptyLayoutQiankun;
}

const accessCardRouter = {
  path: "/visitors",
  component: Layout,
  redirect: "noRedirect",
  meta: {
    title: "访客",
    icon: "lock",
  },
  children: [
    {
      path: "frontDesk",
      component: () =>
        import(/* webpackChunkName: "visitors" */ "@/views/visitors/frontDesk"),
      name: "frontDesk",
      meta: { title: "前台设备授权", noCache: true },
    },
    {
      path: "accessSingleConfiguration",
      component: () =>
        import(
          /* webpackChunkName: "visitors" */ "@/views/visitors/accessSingleConfiguration"
        ),
      name: "accessSingleConfiguration",
      meta: { title: "访问单配置", noCache: true },
    },
  ],
};

export default accessCardRouter;
